.auth-page input {
  font-size: 14px;
  color: #111;
  background-color: #FFF;
  border: 1px solid #ddd;
  box-shadow: none;
  display: inline-block;
  padding: 6px 10px 8px 10px;
  margin-bottom: 0px;
  box-sizing: border-box;
  height: 36px;
  width: 100%;
}
.auth-page input:focus {
  outline: none;
  border: 1px solid #60a5fc;
}
.auth-page input[readonly] {
  cursor: default;
  background-color: #EEE;
  opacity: 0.8;
}
.auth-page input[readonly]:focus {
  border: none;
}
.auth-page a {
  text-decoration: none;
  color: #60a5fc;
}
.auth-page a:hover {
  color: #2e89fb;
}
.auth-page form {
  width: 100%;
  padding-bottom: 50px;
}
.auth-page form .input-option {
  margin-top: 28px;
}
.auth-page form .input-option label {
  display: block;
  text-transform: uppercase;
  font-size: 0.7em;
  padding-bottom: 10px;
  font-weight: bold;
  letter-spacing: 0.1em;
}
.auth-page form .input-option input {
  width: 100%;
  padding: 14px;
  height: 47px;
  font-size: 15px;
  color: #323a4e;
}
.auth-page form .input-option input::placeholder {
  opacity: 0.4;
}
.auth-page form .input-option input:-ms-input-placeholder {
  opacity: 0.4;
}
.auth-page form .submit-wrapper {
  padding-top: 32px;
  vertical-align: middle;
}
.auth-page form .submit-wrapper div.other-option {
  padding: 15px 0px;
  font-size: 90%;
}
.auth-page form .submit-wrapper button[type="submit"],
.auth-page form .submit-wrapper button[type=button],
.auth-page form .submit-wrapper input[type=button] {
  float: right;
  font-size: 13px;
  font-family: 'Open Sans', sans-serif;
  padding: 7px 14px 8px 14px;
  color: white;
  background-color: #60a5fc;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  display: inline-block;
  font-weight: normal;
  -webkit-font-smoothing: auto;
  padding: 15px 20px;
}
.auth-page form .submit-wrapper button[type="submit"]:hover,
.auth-page form .submit-wrapper button[type=button]:hover,
.auth-page form .submit-wrapper input[type=button]:hover {
  text-decoration: none;
}
.auth-page form .submit-wrapper button[type="submit"]:active,
.auth-page form .submit-wrapper button[type=button]:active,
.auth-page form .submit-wrapper input[type=button]:active {
  background-color: #0462d8;
  transition: color 150ms, background-color 150ms;
  outline: none;
}
.auth-page form .submit-wrapper button[type="submit"].disabled,
.auth-page form .submit-wrapper button[type=button].disabled,
.auth-page form .submit-wrapper input[type=button].disabled,
.auth-page form .submit-wrapper button[type="submit"][disabled],
.auth-page form .submit-wrapper button[type=button][disabled],
.auth-page form .submit-wrapper input[type=button][disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.auth-page form .submit-wrapper button[type="submit"].disabled:active,
.auth-page form .submit-wrapper button[type=button].disabled:active,
.auth-page form .submit-wrapper input[type=button].disabled:active,
.auth-page form .submit-wrapper button[type="submit"][disabled]:active,
.auth-page form .submit-wrapper button[type=button][disabled]:active,
.auth-page form .submit-wrapper input[type=button][disabled]:active,
.auth-page form .submit-wrapper button[type="submit"].disabled:hover,
.auth-page form .submit-wrapper button[type=button].disabled:hover,
.auth-page form .submit-wrapper input[type=button].disabled:hover,
.auth-page form .submit-wrapper button[type="submit"][disabled]:hover,
.auth-page form .submit-wrapper button[type=button][disabled]:hover,
.auth-page form .submit-wrapper input[type=button][disabled]:hover {
  background-color: #60a5fc;
}
.auth-page form .submit-wrapper button[type="submit"]:hover,
.auth-page form .submit-wrapper button[type=button]:hover,
.auth-page form .submit-wrapper input[type=button]:hover {
  transition: color 150ms, background-color 150ms;
  background-color: #2e89fb;
}
.auth-page form .submit-wrapper:after {
  content: "";
  display: table;
  clear: both;
}
.auth-page .auth-page-content {
  position: absolute;
  background-color: #FFFFFF;
  left: 342px;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}
.auth-page .auth-form {
  background-color: transparent;
  width: 320px;
  margin: 0 auto;
  margin-top: 128px;
  color: #1E232F;
}
.auth-page .auth-form.session-expired {
  margin-top: 50px;
}
.auth-page .auth-form .form-title {
  font-size: 1.7em;
  margin-bottom: 24px;
}
.auth-page .auth-form .form-instructions {
  font-size: 90%;
  font-weight: 400;
  margin-bottom: 24px;
}
.auth-page .auth-form .error-description {
  color: #FC5343;
}
.auth-page .auth-form .form-success {
  color: #7ED321;
}
.auth-page .auth-form .submit-in-progress {
  text-align: center;
  font-size: 2em;
  color: #60a5fc;
  margin-top: 28px;
}
.auth-page .auth-page-sidebar {
  position: absolute;
  width: 342px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  background-color: #1E232F;
  padding: 50px 42px;
  overflow: auto;
}
.auth-page .auth-page-sidebar .sidebar-header {
  position: absolute;
  top: 128px;
  left: 0;
  color: #FFFFFF;
  font-size: 1.8em;
  font-weight: 400;
  padding: 0px 42px;
  width: 342px;
}
.auth-page .auth-page-sidebar .demo-request-wrapper {
  margin-top: 272px;
}
.auth-page .auth-page-sidebar .demo-request-blurb {
  color: #FFFFFF;
  opacity: 0.5;
  font-size: 1.4em;
  font-weight: 400;
}
.auth-page .auth-page-sidebar .demo-request-link {
  color: inherit;
  text-decoration: inherit;
}
.auth-page .auth-page-sidebar .demo-request-button {
  cursor: pointer;
  display: inline-block;
  margin-top: 24px;
  padding: 15px 28px;
  vertical-align: middle;
  color: #60a5fc;
  border: 1px solid #60a5fc;
  border-radius: 3px;
  font-size: 85%;
}
.auth-page .auth-page-sidebar .demo-request-button:hover {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.auth-page.invite-page .auth-page-content {
  left: 0px;
}
.auth-page.invite-page .auth-form {
  margin-top: 50px;
}
.auth-page.invite-page .invited-user-blurb {
  width: 320px;
  text-align: center;
  margin: 0 auto;
}
.auth-page.invite-page .invited-user-blurb .zoomforth-color-logo {
  width: 130px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
.auth-page.invite-page .invited-user-blurb .zoomforth-color-logo img {
  width: 100%;
  display: block;
}
@media all and (max-width: 800px) {
  .auth-page .auth-page-sidebar {
    display: none;
  }
  .auth-page .auth-page-content {
    left: 0px;
  }
}
