.auth-page .auth-form.login-form .redirect-in-progress {
  margin-top: 10px;
  color: #333;
}
.auth-page .auth-form.login-form .input-option {
  position: relative;
}
.auth-page .auth-form.login-form .input-option a.edit-email {
  cursor: pointer;
  position: absolute;
  top: 36px;
  right: 10px;
}
.auth-page .auth-form.login-form .forgot-password-link-wrapper {
  float: right;
  font-size: 0.7em;
}
.auth-page .auth-form.login-form .form-title {
  margin-bottom: 40px;
}
