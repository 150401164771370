.user-session-expired-banner {
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: #FFF;
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  font-size: 14px;
  font-family: "Lato", Helvetica, Arial, sans-serif;
  line-height: 18px;
  min-width: 400px;
  max-width: 500px;
  margin: 20px auto;
}
.user-session-expired-banner .user-session-expired-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #FFF;
  border-radius: 3px 0 0 3px;
  background-color: #DE363B;
  padding: 15px 20px;
}
.user-session-expired-banner .user-session-expired-body {
  padding: 5px 20px;
}
.user-session-expired-banner .user-session-expired-body h1 {
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 0;
}
.user-session-expired-banner .user-session-expired-body p {
  margin-top: 10px;
}
