.zf-icon-page + .fa-lock {
  bottom: 0.4em;
  right: 0.12em;
  position: absolute;
  font-size: 0.325em;
  color: white;
  z-index: 1;
}
.zf-icon-page + .fa-lock:before {
  position: relative;
  z-index: 1;
}
.zf-icon-page + .fa-lock:after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  right: -0.4em;
  bottom: -0.2em;
  border-radius: 10em;
  border: 0.8em solid #FFCA54;
  box-shadow: 0 0 0 2.25px #fff;
}
.zf-icon-link + .fa-lock {
  bottom: 0.72em;
  right: 0.04em;
  position: absolute;
  font-size: 0.3em;
  color: white;
  z-index: 10;
}
.zf-icon-link + .fa-lock:before {
  position: relative;
  z-index: 1;
}
.zf-icon-link + .fa-lock:after {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  right: -0.39em;
  bottom: -0.2em;
  border-radius: 10em;
  border: 0.78em solid #FFCA54;
}
.zf-icon-preview:before {
  position: relative;
  top: 0.12em;
  cursor: pointer;
}
